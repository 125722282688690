.location-search {
  position: relative;

  &__input {
    height: 45px;
    border: solid 1px #dddddd;
    padding: 10px;
    font-size: 17px;
    font-weight: 200;
    color: #1b1b1b;
    border-radius: 4px;
    width: 500px;
    outline: none;

    &:focus {
      border: solid 1px #53b1f3;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    top: 45px;
    z-index: 999;
    margin-top: 3px;
    border: solid 1px #d4d4d5;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    max-height: 16.02857143rem;
    overflow: hidden;

    & > div {
      border-top: 1px solid #fafafa;
      padding: 0.78571429rem 1.14285714rem !important;
      white-space: normal;
      word-wrap: normal;

      & > span {
        font-weight: 300 !important;
        margin-top: 10px;
      }
    }
  }
}
