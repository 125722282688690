.page-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #f4f6f9;

  &__content {
    max-width: 1700px;
    margin: auto;
    padding: 15px 35px;
    padding-top: 28px;
    padding-bottom: 20px;
    scrollbar-width: none; // Firefox
    position: absolute;
    right: 0;
    left: 80px;
    bottom: 0;
    top: 55px;
    overflow: auto;

    @media (orientation:'portrait'){
      left: 0;
      padding: 15px 7px;
    }

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

