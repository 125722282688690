.client-page {
    &__header {
        display: flex;
        align-items: center;
    }

    &__details {
        position: relative;
        background: white;
        width: 100%;
        border-radius: 10px;
        min-height: 100px;
        margin-bottom: 15px;
        box-shadow: 0px 0px 3px 0px rgba(201, 201, 201, 0.45);
        position: relative;
        padding: 22px;
        padding-bottom: 10px;
        padding-top: 10px;

        &--tight {
            padding: 0px;
            padding-bottom: 5px;
            padding-top: 3px;
            margin-top: 15px;
        }
    }

    &__details-wrapper {
        margin-top: 5px;
        position: relative;
    }
}

@media only screen and (min-width:992px) and (max-width:1024px){
    .client-page {
        margin-left: 3%;
        width: 94vw;
    }  
}

@media only screen and (max-width:600px){
    .client-page {
        margin-left: 3%;
        width: 94vw;
    }
    .client-page__details {
        overflow-x: scroll;
    }
    .client-users__table-wrapper {
        overflow-x: scroll;
    }
}
