.rich-text {
  &__wrapper {
    cursor: text;
    div {
      text-align: center;
    }
  }

  .DraftEditor-editorContainer {
    background: white;
    border: solid 1px #dddddd;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-size: 17px;
    color: #1b1b1b;
    font-weight: 200;
    min-height: 50px;

    div {
      height: 100%;
    }

    &:disabled {
      background: repeating-linear-gradient(
        45deg,
        #fbfbfb,
        #fbfbfb 10px,
        white 10px,
        white 20px
      );
    }

    ::placeholder {
      color: #1b1b1b;
      opacity: 0.6;
      transition: all 0.3s ease;
      font-weight: 200;
      font-size: 16px;
    }

    &:focus-within {
      outline: none;
      border: solid 1px #53b1f3;

      ::placeholder {
        color: #cdcdcd;
      }
    }
  }

  &--viewer {
    .DraftEditor-editorContainer {
      background: none;
      border: none;
      padding: 0;
    }

    .DraftEditor-editorContainer:focus-within {
      border: none !important;
    }
  }
}
