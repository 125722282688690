.patients {
    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
    }
    
    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;

        @media only screen and (max-width: 600px) {
            width: 100%;
            overflow: auto;
        }
    }

    &__search {
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
}
