.Toastify__toast-container {
  min-width: 320px;
  width: initial;
  max-width: 400px;
  border-radius: 2px !important;
}

.Toastify__toast {
  border-radius: 2px !important;
}

.Toastify__toast-body {
  padding-right: 15px;
}
