.client-details {
    &__content {
      width: 100%;
      border-radius: 3px;
      min-height: 100px;
      margin-bottom: 15px;

      &__tab {
        margin-top: 25px;
        
        .ui.menu{
          box-shadow: none;
          border: none;
          border-radius: 0;
          margin: 0 !important;
          width: 100%;
        }
    
        .ui.bottom.attached.segment.active.tab {
          border: none !important;
          border-radius: 10px;
          margin-top: 15px;
        }
    
        .ui.segment {
          padding: 0;
        }
    
        .ui.secondary.menu .item {
          display: flex;
          justify-content: center;
          font-size: 14px;
          margin: 0 !important;
          padding: 10px 17px !important;
          background: white;
          height: 41px;
          width: 120px;
          margin-right: 10px !important;
          font-weight: 600;
          border-radius: 10px;
          box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.1);
        }

        .ui.secondary.menu .active.item {
          border-radius: 10px !important;
          background: #5076ff !important;
          color: white !important;
          box-shadow: 0px 10px 10px -10px #5076FF;
        }
      }
    }
  
    &__modal-wrapper {
      width: 420px !important;
    }
  
    &__modal {
      display: flex;
      align-items: center;
      flex-direction: column;
  
      &__content {
        width: 100%;
  
        & > div {
          margin: 20px 0;
        }
  
        &__training {
          border: solid 1px grey;
          border-radius: 3px;
          padding: 15px;
          & > div {
            &:first-of-type {
              margin-top: 0;
            }
            margin: 20px 0;
          }
        }
      }
    }
  }
