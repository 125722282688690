.image-card {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  box-shadow: 0px 0px 4px 0px #cdcdcd;
  text-align: center;
  background: white;
  border-radius: 2px !important;
  cursor: pointer;
  width: 238px;
  user-select: none;

  &:hover {
    box-shadow: 0px 0px 4px 0px #73bbf4;
  }

  &:last-of-type {
    margin-right: 10px;
  }

  &__image-wrapper {
    position: relative;
    height: 175px;
    padding: 15px;
    padding-bottom: 5px;

    &__image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: white;
      text-align: center;
      margin: 0 auto;
      border-radius: 15px;
      padding-bottom: 5px;
    }

    &__image-placeholder {
      position: relative;
      height: 100%;
      width: 100%;
      background: white;
    }
  }

  &__footer {
    padding: 5px;
    padding-bottom: 10px;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    user-select: none;
  }
}
