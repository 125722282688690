.patient-details {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: auto;

    &__checkbox {
        margin: auto;
    }

    &__details-wrapper {
        background: white;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        padding: 15px 40px;
        min-height: 77px;
        position: relative;

        &__loader-wrapper {
            min-width: 100%;
            min-height: 100%;
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__loader {
                position: relative !important;
                top: initial !important;
                left: initial !important;
            }
        }

        &__row {
            margin-bottom: 18px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;

        &__date-tag {
            position: absolute;
            top: -16px;
            left: 2px;
        }

        &__section {
            display: flex;

            & > div:first-of-type {
                margin-right: 17px;

                @media only screen and (max-width: 600px) {
                    margin-right: 0;
                }
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;

                &:first-of-type {
                    margin-bottom: 15px;
                }

                & > div {
                    margin-top: 15px;
                }
            }
        }
    }

    &__detail {
        max-width: 17%;
        min-width: 150px;
        width: 16%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-right: 10px;

        &__header {
            margin-bottom: 6px;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        align-items: baseline;
    }
    
    &__table-wrapper {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}

.details-table{
    margin-top: 15px;
}

.patient-details__search__section{
    margin-left: 0px;
}

@media only screen and (max-width:600px){
    .patient-details{
        margin-left: 12px;
        width: 92vw;
    }
    .details-table{
        width: 93vw;
        overflow-x: scroll;
        margin-left: -3px;
    }

    .patient-details__search{
        margin-bottom: 7%;
    }
    .search-bar {
        width: 93vw !important;
    }
    
    .search-bar .input, .djgErZ .search-bar .prompt {
        width: 95%;
    }
    .patient-details__search__section{
        margin-left: 7px;
    }
} 

@media only screen and (min-width:768px) and (max-width:1025px){
    .patient-details{
        margin-left: 28px;
        width: 93vw;
    }
    .patient-details__search{
        margin-bottom: 5%;
    }
    #patient-details-search{
        width: 272px;
    }
      .details-table{
        width: 101%;
       
    }
    .djgErZ .search-bar .input, .djgErZ .search-bar .prompt {
        width: 71%;
    }
}

@media only screen and (min-width:992px){
   
    .patient-details__detail{
        max-width: unset;
        width: unset;
    }
}