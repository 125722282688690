.header-bar {
  border-bottom: solid 1px rgb(238, 238, 238) !important;
  .ui.dropdown .menu {
    border-radius: 5px !important;
    padding: 6px !important;
  }

  &__notif,
  .ui.floating.dropdown > .menu {
    border-radius: 5px !important;

    &__item {
      width: 250px !important;
      white-space: normal !important;
      border-radius: 5px !important;
    }
  }

  .ui.search.dropdown.active>input.search, .ui.search.dropdown.visible>input.search {
    height: 55px !important;
  }

  &__select.select-dropdown.ui.selection.dropdown {
      height: 55px !important;
      border-top: none !important;
      border-radius: 0 !important;
      border-color: rgba(34,36,38,.1) !important;
      padding-top: 3px !important;

      .icon.dropdown {
        padding-top: 20px;
      }
  }

  &__notif-circle {
    background: #ff9b71;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 22px;
    top: 0px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    transform: rotate(-127deg);

    &__text {
      transform: rotate(127deg);
      padding-top: 2px;
    }
  }

  .icon {
    color: #1b1b1b !important;
  }
}

.profile-dropdown {
  margin-right: 20px;
  flex-direction: column;
  display: flex;
  right: 0px;
  top: 10px;
  z-index: 3;
  border-radius: 5px;
  min-width: 180px;
  max-width: 240px;
  overflow: hidden;
  box-shadow: none;
  position: initial;
  background: initial;
  animation: fadein 0.2s;

  &--toggled {
    box-shadow: 0px 0px 4px 0px #8e8d8d8a;
    position: absolute;
    background: white;
    animation: fadein 0.2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (orientation:'landscape'){
  #username{
    display: block;
  }
  .header-bar img{
    display: block;
   }
  
  .header-bar .bars{
    display: none !important;
   }
}


@media only screen and (max-width: 600px) {
  .header-bar img{
   display: none !important;
  }
  .header-bar .QdaBA{
   margin-left: 20px;
   margin-top: 27px;
   display: block;
   }
   .bars :hover{
      cursor: pointer;
   }
  #username{
    display: none !important;
   }
   #name-avatar{
    position: absolute;
    top: 0px;
    right: 5px;
}

.header-bar__select{
  width: 190px !important;
}
   
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
  .header-bar img{
    display: none !important;
   }
}

