.horizontal-menu {
  width: 100%;
}

.menu-wrapper {
  padding-top: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.menu-item-wrapper {
  outline: none;
  height: 140px;

  img {
    padding: 2px;
  }
}
.menu-item.active {
  border: 1px green solid;
}

.menu-item {
  height: 140px;
}

.scroll-menu-arrow {
  position: relative;
  cursor: pointer;
  height: 100%;
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 0 5px;

  &:hover {
    background: #f5f5f5;
  }
}
