@import 'utilities/spacing';
@import 'utilities/typography';

@import 'overrides/table.scss';
@import 'overrides/toastify.scss';
@import 'overrides/modal.scss';
@import 'overrides/calendar.scss';
@import 'overrides/pagination.scss';

@import 'base/base';

@import 'global/inputError.scss';
@page {
  margin: 0;
}
