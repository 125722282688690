.ui.table {
  border: 1px solid rgba(34,36,38,.1) !important;
  box-shadow: 0none !important;

  thead th {
    background: white !important;
    font-size: 15px;
    font-weight: 500 !important;
    border-bottom: solid 1px rgba(34,36,38,.1)  !important;
  }

  .table-row {
    height: 50px;
    font-size: 16px;
    cursor: pointer;

    &__checkbox {
      margin: auto;
    }

    &__cell {
      &__link {
        cursor: pointer;
      }

      &--center {
        text-align: center;
      }
    }

    td {
      border-bottom: solid 1px #f3f3f3;
      border-top: none;
      font-size: 14px;
      color: #1b1b1b;
    }

    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }
}

.ui.table thead tr:first-child > th:first-child {
  border-radius: 10px 0 0 0 !important;
}
.ui.table thead tr:first-child > th:last-of-type {
  border-radius: 0 10px 0 0 !important;
}
.table {
  border-radius: 10px !important;
}

.ui.table thead th {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  height: 48px;
  color: black !important;
}
