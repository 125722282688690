.client-group {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 10px;
  padding-top: 0;

  &__header {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  &__table-wrapper {
    margin-top: 15px;
    margin-right: 50px;

    &__inputs {
      display: flex;
      align-items: end;
    }
  }

  &__table {
    min-width: 360px;
  }

  &__tables {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &__user-status {
    border-radius: 30px;
    display: inline-flex;
    padding: 7px 15px;
    &--red {
      background: red;
    }

    &--green {
      background: #45cb45d9;
    }
  }

  &__modal {
      &__content {
          padding: 20px;
          display: flex;
          align-items: center;
          flex-direction: column;
      }

      &__button {
          margin-top: 20px;
      }
  }
}