.client-details-header {
  display: flex;
  align-items: center;

  &__abbreviation {
    height: 50px !important;
    min-width: 50px;
    padding: 0 10px;
    border-radius: 10px;
    background: #5c53f3;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.1);
  }

  &__content {
    margin-left: 10px;

    &__name {
      margin-bottom: 0px;
    }
    &__date {
      line-height: 13px;
      margin-bottom: 0px;
    }
  }
}
