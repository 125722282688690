.create-client {
  &__header {
    display: flex;
    align-items: center;
  }

  &__content {
    background: white;
    box-shadow: 0px 0px 2px 0px rgba(201, 201, 201, 0.5411764705882353);
    width: 100%;
    min-height: 100px;
    margin-top: 15px;
    border-radius: 10px;

    &__info {
      padding: 25px 13px;
      padding-TOP: 10px;
    }

    &__header {
      height: 70px;
      border-bottom: solid 1px rgba(34, 36, 38, 0.15);
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    &__form {
      padding: 12px;
      padding-top: 20px;

      &__input {
        padding: 0 12px;
      }

      &__first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100px;
      }

      &__grid {
        // display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: 110px 110px;
      }

      &__domain {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        &__trail {
          height: 27px;
          font-weight: 100;
          width: 250px;
          color: #cdcdcd;
          border-bottom: dotted 1px #cdcdcd;
          font-size: 17px;
        }
      }

      &__branding {
        margin-top: 10px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        &__inputs {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 30%;
          align-items: baseline;
        }

        &__preview {
          width: 70%;
          height: 100%;
        }

        &__color-picker {
          position: absolute;
          bottom: 0;
          top: 90px;
          left: 24px;
          z-index: 10;
          margin-bottom: 20px;
        }

        &__file-upload {
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          &--no-pointer {
            pointer-events: none;
          }

          &__image {
            margin-top: 10px;
            max-width: 150px;
            margin-bottom: 10px;
          }

          &__image-placeholder {
            margin-top: 20px;
          }
        }
      }

      &__save-wrapper {
        margin-left: 13px;
        margin-bottom: 15px;
        margin-top: 32px;
      }
    }
  }
}

@media only screen and (min-width:768px){
  .create-client-form-columns {
    width: 25% !important;
  }
}