.send-results {
    &__content {
      max-width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__table {
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    }

    &__button {
      margin: auto;
      margin-top: 20px;
    }
}