.mobile__pdf__container{
    height: 100vh;
}

.mobile__pdf__container footer{
    left: 27%;
    width: 65%;
}
.mobile__pdf__container #viewerContainer .pdfViewer {
    overflow: unset;
}
.mobile__pdf__container__header{
    display: none;
}

@media (orientation:'portrait'){
    .mobile__pdf__container footer{
        left: 0;
        width: 100%;
    }
}

@media only screen and (max-width:600px){
    .page   {
        width: 360px !important;
        height: 448px !important;
       
    }

    .canvasWrapper  {
        // width: 360px !important;
        height: 448px !important;
       
    }
}

.result-details__pdf-wrapper {
    width: 100%;
    margin-top: -28px;
    position: relative;
    max-width: 1400px;

    &__pdf {
        width: 100%;
        height: calc(100vh - 55px);
        border-radius: 10px;
    }

    // &__pdf {
    //     display: inline-block;
    //     overflow: auto;
    //     -ms-overflow-style: none;  /* IE and Edge */
    //     scrollbar-width: none;  /* Firefox */

    //     &::-webkit-scrollbar {
    //         display: none;
    //     }

    //     .react-pdf__Document {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         position: relative;
    //         padding: 0 5px;
    //     }

    //     .react-pdf__Page__canvas {
    //         margin: auto;
    //         margin-top: 10px;
    //         margin-bottom: 10px;
    //         box-shadow: 0px 0px 4px 0px #b5b5b5;

    //         &:last-of-type {
    //             margin-bottom: 0;
    //         }
    //     }
    // }
}

.pdf-viewer {
    &__tool-bar {
        background: #5076ff;
        width: calc(100% - 10px);
        height: 40px;
        position: absolute !important;
        top: -20px;
        right: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 5px;
        padding: 0 10px;

        & > div {
            width: 33%;
            display: flex;
            justify-content: center;
            text-align: center;

            &:first-of-type {
                justify-content: start;
            }
            &:last-of-type {
                justify-content: end;
            }
        }
    }

    &__download {
        height: 27px;
        width: 110px;
        z-index: 99;
        background: white;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 14px;
            width: 14px;
        }

        a {
            color: black;
        }
    }
}