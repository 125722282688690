.result-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    margin: auto;

    &__status-dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin-right: 8px;
        margin-bottom: 1px;

        &--green {
          background: #5bc592;
        }
        &--red {
          background: red;
        }
        &--yellow {
          background: #ff9b71;
        }
    }

    &__tab-wrapper {
        width: 100%;
        margin-top: -30px;
    }

    .ui.vertical.menu {
        margin-top: 50px;
        width: 100% !important;
        border-radius: 10px !important;
        border: none !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6) !important;

        @media only screen and (max-width: 600px) {
            margin-top: 10px;
        }
    }

    .ui.grid>[class*="four wide"].column {
        width: 15% !important;
        padding-right: 10px;

        @media only screen and (max-width: 1024px) {
            padding-right: 0;
        }
       
        @media only screen and (max-width: 650px) {
            padding-left: 30px;
            width: 21% !important;
        }
    }

    .ui.grid>.column:not(.row) {
        padding-bottom: 0;
    }

    .ui.grid>.stretched.column:not(.row) {
        width: 85% !important;

        @media only screen and (max-width: 650px) {
            width: 79% !important;
        }
    }

    .active.item {
        font-weight: bold !important;
        background: #5076ff !important;
        color: white !important;
    }

    .item {
        border-radius: 10px !important;
    }

    &__details-wrapper {
        background: white;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 15px 30px;
        min-height: 165px;
        position: relative;

        &__loader-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__loader {
                position: relative;
                top: initial;
                left: initial;
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 7px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    
    &__side-details-wrapper {
        background: white;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(201, 201, 201, 0.6);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 10px;
        min-height: 165px;
        position: absolute;
        left: -199px;
        top: 170px;
        width: 189px;

        @media only screen and (max-width: 1024px) {
            display: none;
        }

        &__loader-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__loader {
                position: relative;
                top: initial;
                left: initial;
            }
        }
    }

    &__test-table {
        margin-top: 35px;
        width: 100%;
        border-radius: 2px !important;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }

    &__detail {
        max-width: 20%;
        min-width: 150px;
        width: 20%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 60px;
        padding-right: 10px;

        &__header {
            margin-bottom: 8px;
        }
        
        &__data {
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    &__side-detail {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__header {
            margin-bottom: 2px;
        }
        
        &__data {
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__pdf-wrapper {
        margin-top: -26px;
        position: relative;
        max-width: 1400px;
        display: flex;
        justify-content: center;
        margin: auto;

        &__pdf {
            height: calc(100vh - 90px);
        }
    }

    &__header {
        max-width: 1400px;
        display: flex;
        width: 100%;
        align-items: baseline;
        z-index: 3;
    }
    
    &__table-wrapper {
        width: 100%;
        padding-top: 25px;
    }
}


@media only screen and (max-width: 600px) {
    .result-details {
        width: 100%;
    }

    .result-details__detail{
        margin-top: 8%;
    }

    .result-details .ui.grid > [class*="four wide"].column {
        width: 42% !important;
        position: absolute;
        top: 4px;
        right: 10px;
        z-index: 99;
    }
    .result-details__header{
        margin-left: 0;
    }
    .result-details .ui.grid > .stretched.column:not(.row) {
        width: 100% !important;
        margin-left: 0;
    }
    .result-details__tab-wrapper{
        width: 100%;
        margin-top: 80px;
    }
   
    .mobile__pdf__container {
        width: 100% !important;
    }

    .ui.grid>.column:not(.row), .ui.grid>.row>.column {
        padding: 0 !important;
    }

    .mobile__pdf__container #viewerContainer {
        top: 20px !important
    }
}