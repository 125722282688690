.results {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    &__header {
        width: 100%;
        margin-bottom: 10px;
    }

    &__checkbox {
        margin: auto;
    }

    &__client-select {
        .ui.selection.dropdown {
            min-height: 40px !important;
            height: 40px;
        }

        .ui.multiple.search.dropdown>input.search {
            min-height: 40px !important;
            height: 40px;
            padding-left: 0px !important;
        }

        .ui.multiple.search.dropdown>.text {
            margin-top: 6px;
            padding-left: 5px;
            padding-top: 3px;
        }
    }

    &__status-dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin: auto;
    
        &--green {
          background: #5bc592;
        }
        &--red {
          background: red;
        }
        &--orange {
          background: #ff9b71;
        }
    }
    
    &__table-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;

        @media only screen and (max-width: 600px) {
            width: 100%;
            overflow: auto;
            margin-top: 130px;
        }

        .ui.search {
            min-height: 35px !important;
            height: 35px !important;
        }

        .icon {
            height: 35px !important;
            padding-top: 9px !important;
        }
    }

    &__search {
        display: flex;
        justify-content: space-between;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        &__date-tag {
            position: absolute;
            top: -16px;
        }

        &__section {
            display: flex;

            & > div:first-of-type {
                margin-right: 17px;

                @media only screen and (max-width: 600px) {
                    margin-right: 0;
                }
            }

            @media only screen and (max-width: 600px) {
                flex-direction: column;

                &:first-of-type {
                    margin-bottom: 15px;
                }

                & > div {
                    margin-top: 15px;
                }
            }
        }
    }
}
.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
    position: absolute;
    top: 0;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}