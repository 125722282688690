$highlight-color: #f2f8fc;

$side-bar-width: 80px;
$side-bar-open-width: 500px;
$side-bar-item-height: 67px;

.side-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: #f4f6ff;

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    width: $side-bar-width;
    overflow: auto;
    background: #fefefe;
    justify-content: center;
    z-index: 2;

    &--open {
      width: $side-bar-open-width;
      transition: width 130ms ease;
    }

    &--closed {
      width: $side-bar-width;
      transition: width 130ms ease;
    }

    &--highlight {
      background: $highlight-color;
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 18px;
  }

  &__item {
    width: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $side-bar-item-height;
    user-select: none;
    cursor: pointer;
    position: relative;
    border-left: solid 3px transparent;
    margin: auto;
    border-radius: 10px;
    margin-top: 13px;
    margin-bottom: 13px;

    &:active {
      box-shadow: 0px 7px 20px -10px #5076FF;
      opacity: 0.99;
    }

    &--selected {
      background: #5076ff;
      color: white;
      box-shadow: 0px 10px 20px -10px #5076FF;
    }

    &__title {
      font-size: 11px !important;
      line-height: 11px !important;
    }

    &__icon {
      position: absolute;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: all 0.3s ease;
      padding-top: 2px;
      width: $side-bar-width;
      background: #181f2c;
      z-index: 9;

      &:hover {
        background: $highlight-color;
      }
    }

    &__icon-wrapper {
      margin-left: -3px;
    }

    &--bottom {
      position: absolute;
      bottom: 0;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      &:hover {
        background: initial;
        border-left: solid 3px transparent;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      font-size: 16px;
      height: 100%;
      width: calc(#{$side-bar-open-width} - #{$side-bar-width});

      &--open {
        opacity: 1;
        transition: opacity 320ms ease-in;
      }
      &--closed {
        opacity: 0;
      }
    }

    &__wrapper {
      height: $side-bar-item-height;
    }
  }

  &__sub-menu {
    // border-radius: 10px 10px 0px 0px;
    position: absolute;
    height: 100%;
    top: 0;
    right: -310px;
    width: 300px;
    z-index: 1;
  }

  &__after {
    top: -7px;
    position: absolute;
    z-index: 4;
    width: 100%;
    background: #fefefe;
    height: 20px;
    
  }
}

.pushable{
  width:80px;
  z-index: 50;
  position: absolute;
  top:54px;
}

.ui.visible.left.overlay.sidebar {
  width: 80px !important;
  border-top-style: none; 
  border-left-style: none; 
  border-bottom-style: none; 
  border-right: solid 1px rgb(238, 238, 238);
}
