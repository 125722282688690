.login {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #184c93;

  &__bckg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--mini {
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(2px);
      transition: opacity ease-in 1000ms;
    }
  }

  &__card {
    background: white;
    max-width: 405px;
    min-width: 405px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 12px 0px rgba(80, 80, 80, 0.5);
    margin-bottom: 50px;

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 30px;
      width: 100%;
      height: 100%;
      margin: auto;
      margin-top: 40px;
    }

    &__logo {
      width: 75%;
      object-fit: contain;
      max-height: 100px;
      margin-top: 47px;
    }

    &__header {
      flex-wrap: wrap;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 0.0125em;
      line-height: 30px;
      word-break: break-all;
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }

    &__input {
      margin-top: 15px;
    }

    &__button {
      margin-top: 50px;
      width: 100% !important;
    }

    &__forgot-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__forgot {
      border: none;
      height: 16px;
      margin-top: 10px;
      margin-bottom: 30px;
      width: initial !important;
      padding: 0 !important;

      &__text {
        font-size: 13px;
        font-weight: 400;
      }

      :hover {
        .login__card__forgot__text {
          color: #85b7d9;
        }
      }
    }
  }
}

.login-modal {
  width: 350px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login__card{
    min-width: initial;
    max-width: initial;
    width: 95%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login__card{
    min-width: 345px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login__card{
    min-width: 470px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login__card{
    min-width: 470px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .login__card{
    min-width: 470px;
  }
}
