.sample-collection {
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .ui.table .table-row {
      cursor: initial;
    }

    &__select-text {
      div.text {
        font-size: 15px !important;
        font-weight: bold !important;
      }

      span.text {
        font-weight: 300 !important;
      }
    }

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;

      @media only screen and (max-width:600px) {
        flex-direction: column;
        align-items: initial;
      }
    }
    
    &__table-wrapper {
        margin-top: 10px;
        min-width: 771px;
        width: 771px;
        
        @media only screen and (max-width:600px) {
          margin-top: 120px;
        }

        .table {
          min-width: 340px !important;
        }

        &--lab {
          margin-right: 0;
          width: 100% !important;
        }

        .ui.search {
          min-height: 35px !important;
          height: 35px !important;
      }

      .icon {
          height: 35px !important;
          padding-top: 9px !important;
      }
    }

    &__content {
      display: flex;
      width: 100%;

      @media only screen and (max-width:600px) {
        width: 94vw;
        overflow-x: scroll;
      }

      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }
    }

    &__cleave {
      display: flex;
      flex-direction: column;
    }

    &__date {
      height: 45px;
      .react-date-picker__wrapper {
        height: 45px !important;
      }
    }

    &__form {
      padding: 20px 30px;
      background: white;
      border-radius: 10px;
      width: 100%;
      box-shadow: 0px 0px 2px 0px rgba(201, 201, 201, 0.5411764705882353) !important;
      margin-top: 10px;
      height: fit-content;
      margin-right: 20px;

      &__grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 0px;

        & > div {
          margin-right: 15px;
          margin-bottom: 20px;
        }
      }
    }

    &__status-dot {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        margin-left: 15px;
        margin-bottom: 1px;

        &--green {
          background: #5bc592;
        }
        &--red {
          background: red;
        }
    }

    &__search {
      width: 381px !important;
    }

    &__text-area {
      width: 500px;
    }
}

@media only screen and (max-width:600px){
  .sample-collection__form {
    margin-top: 45%;
    margin-left: 3px;
    position: absolute;
    width: 94vw;
  }
  .sample-collection__text-area {
    width: 305px;
  }
}


@media only screen and (min-width:600px) and (max-width:1024px){
  .sample-collection {
    margin-left: 3%;
    width: 94vw;   
  }  
  .sample-collection__form {
    margin-top: 26%;
    margin-left: 0px;
    position: absolute;
    width: 94vw;
  }
}

