.client-branding {
  width: 100%;

  &__header {
    border-bottom: solid 1px rgba(34, 36, 38, 0.15);
    padding-bottom: 15px;
    padding-top: 10px;
    padding-left: 20px;
    width: 100%;
  }

  &__content {
    padding: 25px 45px;
    flex-direction: row;
    justify-content: space-between;

    &__data {
      display: flex;
    }
  }

  &__data-wrapper {
    height: 90px;
    margin-right: 40px;
    &__brand-color {
      height: 40px !important;
      width: 40px;
      border-radius: 5px;

      &__color {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }

    &__logo {
      max-height: 36px;
      height: 36px;
      margin-top: 4px;
    }

    &__data {
      display: flex;
      flex-direction: row;
      margin-top: 4px;
    }
  }
}
