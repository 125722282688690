.sub-clients {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px;
  
    &__table-wrapper {
      width: 100%;
      margin-top: 15px;
    }
  
    &__table {
      width: 100%;
      &__user-abbreviation {
        height: 40px !important;
        width: 37px;
        background: #f5f5f5;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    &__user-status {
      border-radius: 30px;
      display: inline-flex;
      padding: 7px 15px;
      &--red {
        background: red;
      }
  
      &--green {
        background: #45cb45d9;
      }
    }
  }

  @media only screen and (max-width:768px){
    .sub-clients__table-wrapper{
      width: 92vw;
      overflow-x: scroll;
    }
  }
  