.settings {
  &__header {
    display: flex;
    align-items: center;
  }

  &__edit-button {
    position: absolute;
    top: 20px;
    right: 30px;
  }

  &__content {
    padding: 20px 45px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 80px 80px;
  }

  &__data-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

    &__data {
      margin-top: 4px;
    }
  }
}

@media only screen and (max-width:600px){
  .settings__content {
    display: flex;
    flex-direction: column;
  }

  .settings__data-wrapper {
    margin-top: 28px;
  }
}