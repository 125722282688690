.client-groups {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px;
  
    &__table-wrapper {
      width: 100%;
      margin-top: 15px;
    }
  
    &__table {
      width: 100%;
      &__user-abbreviation {
        height: 40px !important;
        width: 37px;
        background: #f5f5f5;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    &__user-status {
      border-radius: 30px;
      display: inline-flex;
      padding: 7px 15px;
      &--red {
        background: red;
      }
  
      &--green {
        background: #45cb45d9;
      }
    }

    &__modal {
        &__content {
            padding: 20px;
            padding-top: 0px;
            padding-bottom: 0px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &__button {
            margin-top: 20px;
        }
    }
}

@media only screen and (max-width:600px){
  .client-groups__table-wrapper{
    overflow-x: scroll;
  }
}